import React, { useRef, useEffect } from 'react';
import { object, array, bool, func } from 'prop-types';

import Education from '../Education/Education';

import './EducationList.scss';

const EducationList = ({
  educationItems,
  staticText,
  isSelectedEducation,
  setIsSelectedEducation,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (isSelectedEducation) {
      ref.current.scrollIntoView();
    }
    setIsSelectedEducation(false);
  }, [isSelectedEducation, setIsSelectedEducation]);

  if (!educationItems.length) return null;

  return (
    <div className="education-list" ref={ref}>
      <h1 className="education-list__title">
        {staticText.educationSectionTitle}
      </h1>
      <div>
        {educationItems.map((educationItem) => (
          <Education
            key={educationItem.id}
            id={educationItem.id}
            name={educationItem.name}
            title={educationItem.title}
            endTime={educationItem.endTime}
            startTime={educationItem.startTime}
            date={educationItem.date}
            type={educationItem.type}
            description={educationItem.description}
            pictures={educationItem.picture}
            link={educationItem.link}
            subDescription={educationItem.subDescription}
            staticText={staticText}
          />
        ))}
      </div>
    </div>
  );
};

EducationList.propTypes = {
  educationItems: array,
  staticText: object,
  isSelectedEducation: bool.isRequired,
  setIsSelectedEducation: func.isRequired,
};

EducationList.defaultProps = {
  educationItems: [],
  staticText: {},
};

export default EducationList;
