import React, { useState, useMemo, useEffect } from 'react';

import Sidebar from './components/Sidebar/Sidebar';
import Main from './components/Main/Main';

import {
  useFetchAbout,
  useFetchExhibitions,
  useFetchEvents,
  useFetchStaticText,
  useFetchLanguage,
  useMedia,
  useFetchEducation,
} from './utils/hooks';

import './App.scss';
import { getCurrentExhibitions } from './utils/getCurrentExhibitions';
import { getPreviousExhibitions } from './utils/getPreviouExhibitions';
import { getCurrentEvents } from './utils/getCurrentEvents';
import { getPreviousEvents } from './utils/getPreviousEvents';
import { getUpcomingExhibitions } from './utils/getUpcomingExhibitions';
import { getUpcomingEvents } from './utils/getUpcomingEvents';

const App = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedExhibitionId, setSelectedExhibitionId] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [isSelectedAbout, setIsSelectedAbout] = useState(false);
  const [isSelectedEvent, setIsSelectedEvent] = useState(false);
  const [isSelectedEducation, setIsSelectedEducation] = useState(false);
  const [isSelectedLogo, setIsSelectedLogo] = useState(false);
  const [language, setLanguage] = useFetchLanguage();
  const [exhibitions, exhibitionsError] = useFetchExhibitions(language);
  const [events, eventsError] = useFetchEvents(language);
  const [education, educationError] = useFetchEducation(language);
  const [about, aboutError] = useFetchAbout(language);
  const [staticText, staticTextError] = useFetchStaticText(language);

  const currentExhibitions = useMemo(
    () => getCurrentExhibitions(exhibitions),
    [exhibitions],
  );
  const previousExhibitions = useMemo(
    () => getPreviousExhibitions(exhibitions),
    [exhibitions],
  );
  const upcomingExhibitions = useMemo(
    () => getUpcomingExhibitions(exhibitions),
    [exhibitions],
  );
  const currentEvents = useMemo(() => getCurrentEvents(events), [events]);
  const previousEvents = useMemo(() => getPreviousEvents(events), [events]);
  const upcomingEvents = useMemo(() => getUpcomingEvents(events), [events]);

  const isMobileVersion = useMedia(
    ['(min-width: 768px)', '(min-width: 280px)'],
    [false, true],
  );

  useEffect(() => {
    setIsSidebarOpen(isMobileVersion);
  }, [isMobileVersion]);

  return (
    <>
      {(!!events.length || !!exhibitions.length) && (
        <>
          {!eventsError &&
          !aboutError &&
          !staticTextError &&
          !exhibitionsError &&
          !educationError ? (
            <Sidebar
              events={events}
              exhibitions={exhibitions}
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
              selectedExhibitionId={selectedExhibitionId}
              setSelectedExhibitionId={setSelectedExhibitionId}
              selectedEventId={selectedEventId}
              setSelectedEventId={setSelectedEventId}
              setIsSelectedAbout={setIsSelectedAbout}
              setIsSelectedLogo={setIsSelectedLogo}
              setIsSelectedEducation={setIsSelectedEducation}
              currentExhibitions={currentExhibitions}
              previousExhibitions={previousExhibitions}
              upcomingExhibitions={upcomingExhibitions}
              upcomingEvents={upcomingEvents}
              educationItems={education}
              setIsSelectedEvent={setIsSelectedEvent}
              language={language}
              setLanguage={setLanguage}
              staticText={staticText}
            >
              <Main
                events={events}
                exhibitions={exhibitions}
                about={about}
                setIsSidebarOpen={setIsSidebarOpen}
                selectedExhibitionId={selectedExhibitionId}
                setSelectedExhibitionId={setSelectedExhibitionId}
                selectedEventId={selectedEventId}
                setSelectedEventId={setSelectedEventId}
                isSelectedAbout={isSelectedAbout}
                isSelectedEvent={isSelectedEvent}
                setIsSelectedAbout={setIsSelectedAbout}
                setIsSelectedEvent={setIsSelectedEvent}
                isSelectedLogo={isSelectedLogo}
                setIsSelectedLogo={setIsSelectedLogo}
                isSelectedEducation={isSelectedEducation}
                setIsSelectedEducation={setIsSelectedEducation}
                currentExhibitions={currentExhibitions}
                upcomingExhibitions={upcomingExhibitions}
                previousExhibitions={previousExhibitions}
                currentEvents={currentEvents}
                previousEvents={previousEvents}
                upcomingEvents={upcomingEvents}
                education={education}
                language={language}
                setLanguage={setLanguage}
                staticText={staticText}
              />
            </Sidebar>
          ) : (
            <div className="error-message">
              <h1>OOps</h1>
              <h2>
                Something went wrong. We are working on getting this fixed as
                soon as we can. You may be able to try again
              </h2>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default App;
