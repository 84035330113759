import React, { useRef, useState } from 'react';
import moment from 'moment';
import { string, number, object, array } from 'prop-types';

import Slider from '../Slider/Slider';

import { useMedia } from '../../utils/hooks';

import './Education.scss';
import { getFormattedTime } from '../../utils/getFormatedTime';

const Education = ({
  id,
  name,
  title,
  endTime,
  startTime,
  date,
  type,
  description,
  pictures,
  link,
  subDescription,
  staticText,
}) => {
  const [isOpenSubDescription, setIsOpenSubDescription] = useState(false);
  const ref = useRef();

  const isMobileVersion = useMedia(
    ['(min-width: 768px)', '(min-width: 280px)'],
    [false, true],
  );

  return (
    <div ref={ref} className="education">
      <div className="education__info">
        <div className="education__title-container">
          {name && (
            <span
              className="education__name"
              dangerouslySetInnerHTML={{ __html: `${name}` }}
            />
          )}
          <span
            className="education__title"
            dangerouslySetInnerHTML={{ __html: title ? `, ${title}` : '' }}
          />
        </div>

        <div className="education__subtitle-container">
          {type && (
            <span
              className="education__subtitle"
              dangerouslySetInnerHTML={{ __html: `(${type})` }}
            />
          )}
          {date && (
            <span className="education__date">
              {`${moment(date, 'YYYY-MM-DD').format('MMM D, YYYY')}.${startTime && endTime ? ` ${getFormattedTime(startTime)} — ${getFormattedTime(endTime)}` : ''}`}
            </span>
          )}
        </div>

        {isMobileVersion && !!link.length && (
          <ul className="education__link-wrapper">
            {link
              .filter((item) => item.linkHref)
              .map((item) => (
                <li key={item.id} className="education__link">
                  <a href={item.linkHref}>{item.linkTitle || item.linkHref}</a>
                </li>
              ))}
          </ul>
        )}

        {isMobileVersion ? (
          <div className="education__content-container">
            <div className="education__slider-container">
              <Slider pictures={pictures} orientation="horizontal" />
            </div>
          </div>
        ) : (
          <>
            {!!link.length && (
              <ul>
                {link
                  .filter((item) => item.linkHref)
                  .map((item) => (
                    <li key={item.id} className="education__link">
                      <a href={item.linkHref}>
                        {item.linkTitle || item.linkHref}
                      </a>
                    </li>
                  ))}
              </ul>
            )}
            {description && (
              <h2
                className="education__description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
            {subDescription && (
              <>
                <div className="education__list">
                  <div className="education__btn-container">
                    <button
                      type="button"
                      onClick={() =>
                        setIsOpenSubDescription((prevState) => !prevState)
                      }
                      style={{
                        ...(!isOpenSubDescription && { marginBottom: 0 }),
                      }}
                    >
                      {isOpenSubDescription ? (
                        <span className="read-less">
                          {staticText.eventReadLess}
                        </span>
                      ) : (
                        <span className="read-more">
                          {staticText.eventReadMore}
                        </span>
                      )}
                    </button>
                  </div>
                  {isOpenSubDescription && (
                    <div className="education__list-container">
                      <h3
                        className="education__sub-description"
                        dangerouslySetInnerHTML={{ __html: subDescription }}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>

      <div className="education__content">
        {isMobileVersion ? (
          <>
            {description && (
              <h2
                className="education__description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
            {subDescription && (
              <>
                <div className="education__list">
                  <div className="education__btn-container">
                    <button
                      type="button"
                      onClick={() =>
                        setIsOpenSubDescription((prevState) => !prevState)
                      }
                      style={{
                        ...(!isOpenSubDescription && { marginBottom: 0 }),
                      }}
                    >
                      {isOpenSubDescription ? (
                        <span className="read-less">
                          {staticText.eventReadLess}
                        </span>
                      ) : (
                        <span className="read-more">
                          {staticText.eventReadMore}
                        </span>
                      )}
                    </button>
                  </div>
                  {isOpenSubDescription && (
                    <div className="education__list-container">
                      <h3
                        className="education__sub-description"
                        dangerouslySetInnerHTML={{ __html: subDescription }}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <div className="education__content-container">
            <div className="education__slider-container">
              <Slider pictures={pictures} orientation="horizontal" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Education.propTypes = {
  id: number,
  name: string,
  title: string,
  type: string,
  date: string,
  endTime: string,
  startTime: string,
  description: string,
  subDescription: string,
  pictures: object,
  link: array,
  staticText: object,
};

Education.defaultProps = {
  id: null,
  name: '',
  title: '',
  type: '',
  date: '',
  description: '',
  subDescription: '',
  pictures: {},
  endTime: '',
  startTime: '',
  link: [],
  staticText: {},
};

export default Education;
